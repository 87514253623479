import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
         {
            path: "/Lunch",
            name: "Lunch",
            component: () =>
                import("./views/Lunch.vue")

        },       
        {
            path: "/",
            name: "Start",
            component: () =>
                import("./views/Start.vue")

        },
        {
            path: "/WeatherForecast",
            name: "WeatherForecast",
            component: () =>
                import("./views/WeatherForecast.vue")
        },
        {
            path: "/StockChart",
            name: "StockChart",
            component: () =>
                import("./views/StockChart.vue")
        },
        {
            path: "/FortuneCalculator",
            name: "FortuneCalculator",
            component: () =>
                import("./views/FortuneCalculator.vue")
        },           
        {
            path: "/Miscellaneous/ScreenLeap",
            name: "ScreenLeap",
            component: () =>
                import("./views/Miscellaneous/ScreenLeap.vue")
        },     
        {
            path: "/EconomicConcepts",
            name: "EconomicConcepts",
            component: () =>
                import("./views/EconomicConcepts.vue")
        }
         /*
        {
            path: "/TaskReport/:id",
            name: "TaskReport",
            component: () =>
                import("./views/TaskReport.vue")
        },
        {
            path: "/EffectivenessCheckReport/:id",
            name: "EffectivenessCheckReport",
            component: () =>
                import("./views/EffectivenessCheckReport.vue")
        },
        {
            path: "/InvestigationReport/:id",
            name: "InvestigationReport",
            component: () =>
                import("./views/InvestigationReport.vue")
        },
        {
            path: "/Settings",
            name: "Settings",
            component: () =>
                import("./views/Settings.vue")
        },
        {
            path: "*",
            name: "FallBack",
            component: () =>
                import("./views/ReportSearch.vue")
        }  */
    ]
});
