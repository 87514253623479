import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        ReportsPerPage: "",
        ReportSearchState: {},
        LogInModel: {
            UserName: "",
            Password: "",
            SavedHashedPassword: "",
            Authenticated: false,
            ResultModel: {
                Code: "",
                Success: false,
                ShowAlert: false,
                Message: "",
                LinkToPdf: ""
            }
        },
        mobileMainNav: false
    },

    mutations: {
/* 
        SetReportsPerPage(state, reportsPerPage) {
            state.ReportsPerPage = reportsPerPage;
            localStorage.reportPerPage = state.ReportsPerPage;
        },

        SetReportSearchState(state, reportSearchState) {
            state.ReportSearchState = reportSearchState;
        },

        SetCredentials(state, logInModel) {
            state.LogInModel = logInModel;
            localStorage.UserName = state.LogInModel.UserName;
            localStorage.SavedHashedPassword = state.LogInModel.SavedHashedPassword;
        },

        RemoveCredentials(state, logInModel) {
            state.LogInModel = logInModel;
            if (localStorage.UserName) {
                localStorage.UserName = "";
                state.LogInModel.UserName = "";
            }
            else {
                state.LogInModel.UserName = "";
            }
            if (localStorage.SavedHashedPassword) {
                localStorage.SavedHashedPassword = "";
                state.LogInModel.SavedHashedPassword = "";
            }
            else {
                state.LogInModel.SavedHashedPassword = "";
            }
        }, */

        SetMobileMainNav(state, mobileMainNav) {
            state.mobileMainNav = mobileMainNav;
        }
    },

    getters: {
      /*   getReportsPerPage(state) {
            if (localStorage.reportPerPage) {
                state.ReportsPerPage = localStorage.reportPerPage;
            }
            else {
                state.ReportsPerPage = 50;
            }
            return state.ReportsPerPage;
        },

        getReportSearchState(state) {
            return state.ReportSearchState;
        },

        getCredentials(state) {
            if (localStorage.UserName) {
                state.LogInModel.UserName = localStorage.UserName;
            }
            else {
                state.LogInModel.UserName = "";
            }
            if (localStorage.SavedHashedPassword) {
                state.LogInModel.SavedHashedPassword = localStorage.SavedHashedPassword;
            }
            else {
                state.LogInModel.SavedHashedPassword = "";
            }
            return state.LogInModel;
        },

        isAuthenticated(state) {
            if (state.LogInModel.Authenticated) {
                return state.LogInModel.Authenticated;
            }
            else {
                return false;
            }
        } */
    },

    actions: {}
});
