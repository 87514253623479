import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import BootstrapVue from 'bootstrap-vue'
import router from "./router";
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(BootstrapVue);
Vue.use(VueAxios, axios)

//Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFilePdf, faFileAlt, faSearch, faExclamationCircle, faQuestionCircle, faSlidersH, faSignInAlt, faUser, faEye, faEyeSlash, faTimesCircle, faBars, faTimes, faPrint)

Vue.component('font-awesome-icon', FontAwesomeIcon)
//End Font awesome

Vue.config.productionTip = false

window.axios = require('axios');

//Local host 
//axios.defaults.baseURL = 'https://localhost:5001/';

//Production 
//axios.defaults.baseURL = 'https://elmbergdevelopmentwebapi.azurewebsites.net/';

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
