<template>
  <div id="app" class="start-page">
    <!--<div class="mobile-navigation">
      <div class="mobile-top-nav" title="" v-on="handleScroll()" v-bind:class="{ 'navigation unpinned': scrolled }">
        <img class="ed-mobile-logo" alt="Elmberg development" src="./assets/images/logga.jpg" />
        <span v-if="mobileMenuIcon" data-toggle="tooltip" title="Open menu" @click="OpenMobileMenu()">
          <font-awesome-icon class="mobile-menu-icon float-right" :icon="['fas', 'bars']" />
        </span>
        <span v-if="!mobileMenuIcon" data-toggle="tooltip" title="Close menu" @click="CloseMobileMenu()">
          <font-awesome-icon class="mobile-menu-icon float-right" :icon="['fas', 'times']" />
        </span>
      </div>
      <div v-if="mobileMainNav" class="mobile-main-nav">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <ul>
              <li>
                <b-link title="Lunch" class="mobile-nav-item" to="/Lunch" @click="CloseMobileMenu()"><span class="mobile-nav-item-text">Lunch</span></b-link
                ><br />
              </li>
              <li>
                <b-link title="Application settings" class="mobile-nav-item" @click="CloseMobileMenu()" to="/WeatherForecast"><span class="mobile-nav-item-text">Weather</span></b-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>-->
    <div class="header" id="header">
      <div class="main-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-6 col-6">
              <a target="_self" href="/">
                <img class="ed-logo" src="./assets/images/logga.jpg" />
              </a>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-2 col-2"></div>
            <div class="col-lg-2 col-md-2 col-sm-4 col-4">
              <span v-if="leftMenuIcon" data-toggle="tooltip" title="Open menu" @click="OpenLeftMenu()">
                <font-awesome-icon class="menu-icon float-right" :icon="['fas', 'bars']" />
              </span>
              <span v-if="!leftMenuIcon" data-toggle="tooltip" title="Close menu" @click="CloseLeftMenu()">
                <font-awesome-icon class="menu-icon float-right" :icon="['fas', 'times']" />
              </span>
              <div v-if="leftMenu" v-bind:class="{ 'left-menu-open': leftMenuOpen === true }" class="left-menu">
                <ul>
                  <li><b-link to="/" @click="ScrollToTop()">Start</b-link></li>
                </ul>
                <div class="separator"></div>
                <ul>
                  <li><b-link to="/" @click="MenuHandler('stockAnalysis')">Stock analysis</b-link></li>
                  <li><b-link title="Economic concepts" to="/EconomicConcepts" @click="ScrollToTop()">Economic concepts</b-link></li>
                  <li><b-link to="/" @click="MenuHandler('youTubers')">You Tubers</b-link></li>
                  <li><b-link to="/" @click="MenuHandler('goodToKnow')">Good to know</b-link></li>
                  <li><b-link to="/" @click="MenuHandler('stockBrokers')">StockBrokers</b-link></li>
                  <li><b-link to="/" @click="MenuHandler('hydrogen')">Hydrogen</b-link></li>
                  <li><b-link to="/" @click="MenuHandler('solarEnergy')">Solar energy</b-link></li>
                </ul>
                <div class="separator screen-leap"></div>
                <ul class="screen-leap">
                  <li><b-link title="Lunch" to="/Miscellaneous/ScreenLeap" @click="ScrollToTop()">Miscellaneous</b-link></li>
                </ul>
                <div class="separator"></div>
                <ul>
                  <li><b-link title="Weather forecast" to="/WeatherForecast" @click="ScrollToTop()">Weather forecast</b-link></li>
                  <li><b-link title="Lunch" to="/Lunch" @click="ScrollToTop()">Lunch</b-link></li>
                </ul>
                <div class="separator"></div>
                <ul>
                  <li><b-link title="Fortune calculator" to="/FortuneCalculator" @click="ScrollToTop()">Fortune calculator</b-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="main" class="main">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <router-view />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="footer" id="footer">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="footer-heading">Sitemap</div>
                <ul class="footer-menu">
                  <li><a target="_self" href="/WeatherForecast">Weather forecast </a></li>
                  <li><a target="_self" href="/Lunch">Lunch</a></li>
                  <li><a target="_self" href="/StockChart">Stock chart</a></li>
                </ul>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="footer-heading">Contact</div>
                <div class="footer-contact">
                  <ul class="footer-menu">
                    <li><a href="mailto:kalle.elmberg@gmail.com">Epost</a></li>
                    <li><a target="blank" href="https://se.linkedin.com/in/karlelmberg">Linkedin</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="footer-heading">Powered by</div>
                <ul class="footer-menu">
                  <li><img src="./assets/images/icons/vue-js.svg" /></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-to-top">
      <a target="_self" @click="ScrollToTop()"><img src="./assets/images/icons/mouse-cursor.png"/></a>
    </div>
  </div>
</template>

<script>
import "../src/styles/_start-page.scss";
import "../src/styles/_navigation.scss";
import "../src/styles/_header.scss";
import "../src/styles/_footer.scss";
import "../src/styles/_layout.scss";
import store from "../src/store.js";

export default {
  name: "App",
  components: {},

  data: function() {
    return {
      isActive: false,
      ShowSpinner: false,
      limitPosition: 250,
      scrolled: false,
      lastPosition: 100,
      mobileMainNav: false,
      mobileMenuIcon: true,
      NoMobileMenuIcon: false,
      leftMenu: false,
      leftMenuIcon: true,
      leftMenuOpen: false,
    };
  },

  methods: {
    OpenLeftMenu: function() {
      this.leftMenu = true;
      this.leftMenuOpen = true;
      this.leftMenuIcon = false;
      //store.commit("SetMobileMainNav", this.mobileMainNav);
    },

    CloseLeftMenu: function() {
      this.leftMenu = false;
      this.leftMenuOpen = false;
      this.leftMenuIcon = true;
      //store.commit("SetMobileMainNav", this.mobileMainNav);
    },

    OpenMobileMenu: function() {
      this.mobileMainNav = true;
      this.mobileMenuIcon = false;
      store.commit("SetMobileMainNav", this.mobileMainNav);
    },

    CloseMobileMenu: function() {
      this.mobileMainNav = false;
      this.mobileMenuIcon = true;
      store.commit("SetMobileMainNav", this.mobileMainNav);
    },

    MenuHandler: function(linkId) {
      this.CloseLeftMenu();
      document.getElementById(linkId).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },

    ScrollToTop: function() {
      this.CloseLeftMenu();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },

    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        // move up!
      }

      if (this.lastPosition > window.scrollY) {
        this.scrolled = false;
        // move down
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
